import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { useEffect, useContext } from "react"
import { motion } from "framer-motion"
import {
    usePaginationState,
    borderPropertyControl,
    Border,
    fillProp,
    createBackground,
    superfieldsId,
} from "https://framer.com/m/SuperfieldsShared-hv54.js@AK8TVQcEmfucijzWNrrV"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function PaginationButtons(props) {
    const { superfieldsId, type, border, disabled, hover, transition } = props
    const id = props.id ?? "a" + String(Math.floor(Math.random() * 999999999))
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const [paginationState, setPaginationState] = usePaginationState()
    const state = paginationState?.[superfieldsId]
    const paginationActive = state?.active ?? false

    const borderRadius = props.radiusIsMixed
        ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
        : `${props.radius}px`

    const baseAnimate = {
        ...createBackground(props.fill),
        color: props.fontColor,
        opacity: 1,
    }

    const buttonStyle = {
        position: "relative",
        display: "flex",
        justifyContent: props.font?.textAlign || "center",
        alignItems: "center",
        padding: props.paddingIsMixed
            ? `${props.paddingTop}px ${props.paddingRight}px ${props.paddingBottom}px ${props.paddingLeft}px`
            : `${props.padding}px`,
        borderRadius,
        userSelect: "none",
        flex: props.sizing == "fill" ? "1 0 0" : undefined,
        boxShadow: props.shadows,
        cursor: "pointer",
        ...props.font,
        ...props.style,
    }

    const disabledStyle = { cursor: "default" }
    let disabledAnimate = baseAnimate
    if (disabled.mode == "show") {
        disabledAnimate = {
            opacity: disabled.opacity,
            ...createBackground(disabled.fill),
            color: disabled.fontColor ?? baseAnimate.color,
        }
    } else {
        if (disabled.preserveSpace) {
            disabledStyle.visibility = "hidden"
        } else {
            disabledStyle.display = "none"
        }
    }

    let hoverStyle = hover
        ? {
              ...createBackground(hover.fill),
              scale: hover.scale,
              opacity: hover.opacity,
              rotate: hover.rotate,
              color: hover.fontColor,
          }
        : {}

    const borderElement = border && (
        <Border {...border} borderRadius={borderRadius} />
    )

    const setPage = (newPage) => {
        setPaginationState((prev) => {
            return {
                [superfieldsId]: {
                    ...prev[superfieldsId],
                    page: newPage,
                },
            }
        })

        paginationState[superfieldsId]?.onPaginationChange?.()
    }

    function onPrevNextButtonClick(isNext) {
        if (paginationActive && state) {
            const newPage = isNext ? state.page + 1 : state.page - 1

            if (newPage >= 0 && newPage < state.totalPages) {
                setPage(newPage)
            }
        }
    }

    function onLoadMoreButtonClick() {
        if (paginationActive && state && state.page + 1 < state.totalPages) {
            setPage(state.page + 1)
        }
    }

    function onLoadAllButtonClick() {
        if (paginationActive && state && state.page + 1 < state.totalPages) {
            setPage(state.totalPages - 1)
        }
    }

    const buttons = []
    switch (type) {
        case "prevNext":
            const showPrev =
                isCanvas || !paginationActive || (state && state.page > 0)
            const showNext =
                isCanvas ||
                !paginationActive ||
                (state && state.page < state.totalPages - 1)

            buttons.push(
                <motion.div
                    whileHover={showPrev ? hoverStyle : disabledAnimate}
                    animate={
                        showPrev
                            ? baseAnimate
                            : { ...baseAnimate, ...disabledAnimate }
                    }
                    style={{
                        ...buttonStyle,
                        ...(!showPrev && disabledStyle),
                    }}
                    onClick={() => onPrevNextButtonClick(false)}
                    initial={false}
                    transition={transition}
                >
                    {props.buttonContent == "text"
                        ? props.prevButtonText
                        : arrowIcon(props.arrowIcons, false)}
                    {borderElement}
                </motion.div>
            )
            buttons.push(
                <motion.div
                    whileHover={showNext ? hoverStyle : disabledAnimate}
                    animate={
                        showNext
                            ? baseAnimate
                            : { ...baseAnimate, ...disabledAnimate }
                    }
                    style={{
                        ...buttonStyle,
                        ...(!showNext && disabledStyle),
                    }}
                    onClick={() => onPrevNextButtonClick(true)}
                    initial={false}
                    transition={transition}
                >
                    {props.buttonContent == "text"
                        ? props.nextButtonText
                        : arrowIcon(props.arrowIcons, true)}
                    {borderElement}
                </motion.div>
            )

            break
        case "loadMore":
        case "loadAll":
            const showLoadMore =
                isCanvas ||
                !paginationActive ||
                (state && state.page < state.totalPages - 1)

            buttons.push(
                <motion.div
                    whileHover={showLoadMore ? hoverStyle : disabledAnimate}
                    animate={
                        showLoadMore
                            ? baseAnimate
                            : { ...baseAnimate, ...disabledAnimate }
                    }
                    style={{
                        ...buttonStyle,
                        ...(!showLoadMore && disabledStyle),
                    }}
                    onClick={
                        type == "loadMore"
                            ? onLoadMoreButtonClick
                            : onLoadAllButtonClick
                    }
                    initial={false}
                    transition={transition}
                >
                    {(type == "loadMore"
                        ? props.loadMoreButtonText
                        : props.loadAllButtonText
                    )
                        .replace(
                            /\[items\]/g,
                            state
                                ? Math.min(
                                      (state.page + 1) * state.itemsPerPage,
                                      state.totalItems
                                  )
                                : 1
                        )
                        .replace(
                            /\[totalItems\]/g,
                            state ? state.totalItems : 1
                        )}
                    {borderElement}
                    {disabledStyle.display == "none" && !showLoadMore && (
                        <style>{`
                            div:has(> #${id}) {
                                display: none;
                            }
                        `}</style>
                    )}
                </motion.div>
            )

            break
    }

    // Fix for pagination state not loading initially
    useEffect(() => {
        setPaginationState({})
    }, [])

    return (
        <div
            id={id}
            style={{
                display: "flex",
                flexDirection: "row",
                gap: props.gap,
                ...props.style,
            }}
        >
            {buttons}
        </div>
    )
}

PaginationButtons.displayName = "Pagination Buttons"

addPropertyControls(PaginationButtons, {
    ...superfieldsId(),
    type: {
        type: ControlType.Enum,
        defaultValue: "pagination",
        options: ["prevNext", "loadMore", "loadAll"],
        optionTitles: [
            "Prev/Next Buttons",
            "Load More Button",
            "Load All Button",
        ],
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
    },
    buttonContent: {
        type: ControlType.Enum,
        defaultValue: "text",
        options: ["text", "arrows"],
        optionTitles: ["Text", "Arrows"],
        displaySegmentedControl: true,
        title: "Content",
        hidden: (props) => props.type != "prevNext",
    },
    prevButtonText: {
        type: ControlType.String,
        defaultValue: "Previous",
        title: "Previous Text",
        hidden: (props) =>
            props.buttonContent != "text" || props.type != "prevNext",
    },
    nextButtonText: {
        type: ControlType.String,
        defaultValue: "Next",
        title: "Next Text",
        hidden: (props) =>
            props.buttonContent != "text" || props.type != "prevNext",
    },
    loadMoreButtonText: {
        type: ControlType.String,
        defaultValue: "Load More ([items] / [totalItems])",
        displayTextArea: true,
        title: "Button Text",
        description:
            "*[items]*: number of visible items\n*[totalItems]*: total number of items",
        hidden: (props) => props.type != "loadMore",
    },
    loadAllButtonText: {
        type: ControlType.String,
        defaultValue: "Load All ([items] / [totalItems])",
        displayTextArea: true,
        title: "Button Text",
        description:
            "*[items]*: number of visible items\n*[totalItems]*: total number of items",
        hidden: (props) => props.type != "loadAll",
    },
    arrowIcons: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#FFF",
            },
            size: {
                type: ControlType.Number,
                defaultValue: 24,
                min: 1,
                step: 1,
            },
            stroke: {
                type: ControlType.Number,
                defaultValue: 2,
                min: 0.01,
                step: 0.01,
            },
        },
        hidden: (props) =>
            props.type != "prevNext" || props.buttonContent != "arrows",
    },
    fill: fillProp({
        color: "#000",
        gradientColorA: "#8C8C8C",
        gradientColorB: "#000",
    }),
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
        hidden: (props) =>
            props.type == "prevNext" && props.buttonContent == "arrows",
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        hidden: (props) =>
            props.type == "prevNext" && props.buttonContent == "arrows",
    },
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 12,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 8,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        hidden: (props) => props.type != "prevNext",
    },
    border: borderPropertyControl(),
    shadows: {
        type: ControlType.BoxShadow,
    },
    hover: {
        type: ControlType.Object,
        buttonTitle: "Style",
        optional: true,
        icon: "effect",
        controls: {
            fill: fillProp({ noDefault: true }),
            fontColor: {
                type: ControlType.Color,
                optional: true,
            },
            opacity: {
                type: ControlType.Number,
                defaultValue: 1,
                min: 0,
                max: 1,
                step: 0.01,
            },
            scale: {
                type: ControlType.Number,
                defaultValue: 1,
                min: 0.1,
                step: 0.01,
            },
            rotate: {
                type: ControlType.Number,
                defaultValue: 0,
                min: -360,
                max: 360,
                step: 1,
            },
        },
    },
    disabled: {
        type: ControlType.Object,
        controls: {
            mode: {
                type: ControlType.Enum,
                defaultValue: "hide",
                options: ["hide", "show"],
                optionTitles: ["Hide", "Show"],
                displaySegmentedControl: true,
            },
            preserveSpace: {
                type: ControlType.Boolean,
                defaultValue: false,
                description:
                    "Preserve the space where a disabled button would be while hidden.",
                hidden: (props) => props.mode !== "hide",
            },
            fill: fillProp({
                noDefault: true,
                hidden: (props) => props.mode !== "show",
            }),
            fontColor: {
                type: ControlType.Color,
                optional: true,
                hidden: (props) => props.mode !== "show",
            },
            opacity: {
                type: ControlType.Number,
                defaultValue: 0.5,
                min: 0,
                max: 1,
                step: 0.01,
                hidden: (props) => props.mode !== "show",
            },
        },
    },
    transition: {
        type: ControlType.Transition,
        defaultValue: {
            type: "spring",
            stiffness: 1000,
            damping: 60,
            mass: 1,
        },
    },
})

function arrowIcon(props, right) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 24 24"
            stroke-width={props.stroke}
            stroke={props.color}
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={{ display: "block", rotate: right ? "180deg" : undefined }}
        >
            <path d="M15 6l-6 6l6 6"></path>
        </svg>
    )
}
